<template>
  <div>
    <div class="logo-box">
      <img class="logo" src="../assets/xbc-logo.jpg" alt="">
      <div class="text">小包车服务</div>
    </div>
    <button class="btn" @click="getWechatScheme">点击唤醒小程序</button>
  </div>
</template>

<script>
import axios from 'axios'
import {onBeforeMount, reactive, toRefs} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: 'Miniprogram',
  setup() {
    const route = useRoute()
    const state = reactive({
      // baseApi: 'https://qm.nat.polybigdata.cn',
      baseApi: 'https://dapi.kuaiguiqx.com',
    })

    const getWechatScheme = (code) => {
      axios.get(state.baseApi + '/driver/user/url_scheme/get', {
        params: {
          code
        }
      }).then(res => {
        window.location.href = res.data.data.openlink
      })
    }

    const getMnpScheme = () => {
      axios.get(state.baseApi + '/driver/user/url_scheme/get_xbc').then(res => {
        window.location.href = res.data.data.openlink
      })
    }

    onBeforeMount(() => {
      const code = route.query.iv
      if (code) {
        getWechatScheme(code)
      } else {
        getMnpScheme()
      }
    })

    return {
      ...toRefs(state),
      getWechatScheme,
      getMnpScheme
    }
  }
}
</script>

<style scoped>
  .logo-box {
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo {
    width: 100px;
    height: 100px;
    border-radius: 18px;
  }
  .text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .btn {
    border: 0;
    background-color: #0061bd;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 20px;
    color: #fff;
    border-radius: 2px;
    margin-top: 20%;
  }
</style>
